import React, { useState } from "react";

const fachgebiete = [
  {
    title: "Miet- & Wohnungseigentumsrecht",
    description:
      "Beratung bei mietrechtlichen Fragen, Wohnungseigentumsstreitigkeiten und Modernisierungsmaßnahmen.",
    topics: [
      "Rücksichtslose Nachbarn",
      "Fristlose und ordentliche Kündigungen",
      "Konflikte um Schönheitsreparaturen",
      "Mietminderung bei Mängeln (z. B. Schimmel)",
      "Ruhestörung & Lärmbelästigung",
      "Eigenbedarfskündigungen",
      "Modernisierungsmaßnahmen im WEG",
      "Schadensersatz bei (Wasser)schäden",
    ],
  },
  {
    title: "Baurecht",
    description:
      "Rechtliche Begleitung von Bauvorhaben, von der Vertragsprüfung bis zur Mängelbeseitigung.",
    topics: [
      "Vertragsrecht im Bauwesen",
      "Bauplanung & Genehmigungen",
      "Baumängel & Gewährleistung (Feststellung, Durchsetzung von Ansprüchen)",
    ],
  },
  {
    title: "Arbeitsrecht",
    description:
      "Unterstützung bei arbeitsrechtlichen Auseinandersetzungen, Arbeitsverträgen und Kündigungen.",
    topics: [
      "Vergütungsfragen",
      "Ordentliche & außerordentliche Kündigungen",
      "Streitigkeiten um Arbeitszeugnisse",
      "Abmahnungen",
      "Urlaubsregelungen",
    ],
  },
  {
    title: "Familienrecht",
    description:
      "Beratung in familiären Angelegenheiten wie Ehe, Scheidung, Unterhalt und Betreuung.",
    topics: [
      "Eheliche & nichteheliche Lebensgemeinschaften",
      "Scheidung & Unterhaltsfragen",
      "Güterrechtliche Regelungen",
      "Betreuung & Verwandtschaft",
    ],
  },
  {
    title: "Verkehrsrecht",
    description:
      "Unterstützung bei Problemen rund um den Straßenverkehr, von Bußgeldern bis zu Unfällen.",
    topics: [
      "Fehlerhafte Bußgeldbescheide",
      "Geschwindigkeitsüberschreitungen",
      "Punkte in Flensburg",
      "Verkehrsunfälle, Geltendmachung von Schadensersatzansprüchen",
    ],
  },
  // {
  //   title: "Erbrecht",
  //   description:
  //     "Hilfe bei allen Fragen zur Erbfolge, Erbansprüchen und Nachlassregelungen.",
  //   topics: [
  //     "Erbfähigkeit & gesetzliche Erbfolge",
  //     "Gewillkürte Erbfolge",
  //     "Rechtsstellung der Erben",
  //     "Pflichtteilsrecht",
  //     "Erbscheinverfahren",
  //   ],
  // },
];

const FachgebieteSection = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectedItem = fachgebiete[selectedIndex];

  return (
    <section id="fachgebiete" className="py-20">
      <div className="container mx-auto break-words">
        <h2 className="text-3xl font-bold text-blue-ra">Fachgebiete</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-10">
          {/* Left Column: List of Fachgebiete */}
          <div className="space-y-2">
            {fachgebiete.map((item, index) => (
              <button
                key={index}
                onClick={() => setSelectedIndex(index)}
                className={`w-full text-left text-lg p-4 rounded transition-colors duration-200 shadow ${
                  selectedIndex === index
                    ? "bg-blue-ra text-white border-b-4 border-yellow-ra"
                    : "bg-white hover:bg-gray-100"
                }`}
              >
                {item.title}
              </button>
            ))}
          </div>

          {/* Right Column: Selected Fachgebiet Details */}
          <div className="md:col-span-2 break-words">
            <div className="relative">
              <div
                key={selectedIndex}
                className="transition-opacity duration-300 opacity-100"
              >
                <div className="bg-white shadow p-6 rounded">
                  <div className="inline-block max-w-full">
                    <h3 className="text-xl font-bold text-blue-ra">
                      {selectedItem.title}
                    </h3>
                    <div className="bg-yellow-ra h-[2px] w-full mt-4"></div>
                  </div>

                  <p className="text-gray-700 mt-4">
                    {selectedItem.description}
                  </p>

                  {selectedItem.topics && selectedItem.topics.length > 0 && (
                    <ul className="list-disc list-inside mt-4 text-gray-700 space-y-1">
                      {selectedItem.topics.map((topic, i) => (
                        <li key={i}>{topic}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FachgebieteSection;
