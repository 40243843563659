import { FiPhone, FiMail, FiMapPin, FiExternalLink } from "react-icons/fi";

const CTA = () => {
  return (
    <section className="bg-blue-ra text-yellow-ra py-20">
      <div className="container mx-auto flex flex-col items-center text-center">
        <h2 className="text-3xl text-white font-bold mb-2">
          Benötigen Sie rechtliche Unterstützung?
        </h2>
        <p className="max-w-2xl">
          Kontaktieren Sie uns für eine unverbindliche Erstberatung oder weitere
          Informationen.
        </p>
      </div>

      <div className="container mx-auto flex flex-col sm:flex-row items-stretch gap-10 mt-10">
        {/* Telephone Card */}
        <a
          href="tel:07151959930"
          className="flex-1 flex flex-col items-center justify-center p-4 border border-white/50 rounded hover:bg-white/10 transition-colors"
        >
          <FiPhone className="text-3xl mb-2" />
          <p className="text-xl font-semibold">Telefon</p>
          <p className="mt-1 text-center">07151 959930</p>
        </a>

        {/* Email Card */}
        <a
          href="mailto:kanzlei@ra-waiblingen.de"
          className="flex-1 flex flex-col items-center justify-center p-4 border border-white/50 rounded hover:bg-white/10 transition-colors"
        >
          <FiMail className="text-3xl mb-2" />
          <p className="text-xl font-semibold">E-Mail</p>
          <p className="mt-1 text-center">kanzlei@ra-waiblingen.de</p>
        </a>

        {/* Address Card */}
        <a
          href="https://maps.app.goo.gl/9cVZcRhmNa91UsnUA"
          target="_blank"
          rel="noopener noreferrer"
          className="flex-1 flex flex-col items-center justify-center p-4 border border-white/50 rounded hover:bg-white/10 transition-colors"
        >
          <FiMapPin className="text-3xl mb-2" />
          <div className="text-xl font-semibold block focus:outline-none focus:ring-2 focus:ring-white inline-flex items-center gap-1">
            <FiExternalLink />
            Adresse
          </div>
          <p className="mt-1 text-center">
            Fronackerstraße 22
            <br />
            71332 Waiblingen
          </p>
        </a>
      </div>
    </section>
  );
};

export default CTA;
