import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";

const words = [
  "Mietrecht",
  "Baurecht",
  "Arbeitsrecht",
  "Familienrecht",
  "Verkehrsrecht",
  // "Erbrecht",
];

const Hero = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  // Cycle to the next word every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prev) => (prev + 1) % words.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/* Hero Header with background image and custom gradient */}
      <section
        className="relative h-[12rem] h-[16rem] md:h-[32rem] lg:h-[48rem] bg-hero-bg-sm md:bg-hero-bg bg-cover bg-center"
        role="banner"
      >
        <div className="container mx-auto h-full relative">
          {/* Overlay box at bottom-left, visible on md+ */}
          <div className="hidden md:block absolute bottom-20 left-0 container text-white">
            <AnimatePresence mode="popLayout" initial={false}>
              <motion.div
                key={currentWordIndex}
                initial={{ y: "30%", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: "-30%", opacity: 0 }}
                transition={{ duration: 0.4 }}
              >
                <h1 className="text-4xl md:text-4xl lg:text-6xl font-bold drop-shadow">
                  {words[currentWordIndex]}
                </h1>
              </motion.div>
            </AnimatePresence>

            <h2 className="text-lg md:text-xl mt-6">
              Ihre Experten aus Waiblingen. Seit 1932.
            </h2>

            <div className="mt-8">
              <Link
                to="/kontakt"
                className="inline-block text-blue-ra bg-yellow-ra hover:bg-dark-yellow-ra font-semibold py-4 px-4 rounded transition-colors shadow"
              >
                Jetzt Termin vereinbaren
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Content block below image: visible on small screens only */}
      <section className="md:hidden bg-blue-ra text-white text-center p-6">
        <AnimatePresence mode="popLayout" initial={false}>
          <motion.div
            key={currentWordIndex}
            initial={{ y: "-30%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "30%", opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            <h2 className="text-3xl font-bold drop-shadow">
              {words[currentWordIndex]}
            </h2>
          </motion.div>
        </AnimatePresence>

        <h2 className="text-lg mt-2">
          Ihre Experten aus Waiblingen. Seit&nbsp;1932.
        </h2>

        <div className="mt-4">
          <Link
            to="/kontakt"
            className="inline-block text-blue-ra bg-yellow-ra hover:bg-dark-yellow-ra font-semibold py-4 px-4 rounded transition-colors"
          >
            Jetzt Termin vereinbaren
          </Link>
        </div>
      </section>
    </>
  );
};

export default Hero;
