import React from "react";
import { Link } from "react-router-dom";
import IconLink from "./IconLink";

const FooterColumn = ({ heading, children }) => {
  return (
    <div>
      <div className="inline-block">
        <h3 className="text-xl text-yellow-ra font-bold">{heading}</h3>
        <div className="bg-yellow-ra h-[2px] w-full mt-4"></div>
      </div>
      <div className="mt-4">{children}</div>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="bg-blue-ra text-white pt-20 pb-10">
      <div className="container mx-auto grid gap-10 md:grid-cols-3">
        {/* Adresse */}
        <FooterColumn heading="Adresse">
          <p>Rechtsanwälte Schmid, Leibfritz & Kollegen</p>
          <p>Fronackerstraße 22</p>
          <p>71332 Waiblingen</p>
          <IconLink
            href="https://maps.app.goo.gl/9cVZcRhmNa91UsnUA"
            target="_blank"
            rel="noopener noreferrer"
            className="underline block mt-2 focus:outline-none focus:ring-2 focus:ring-white"
          >
            Auf Karte anzeigen
          </IconLink>
        </FooterColumn>

        {/* Kontakt */}
        <FooterColumn heading="Kontakt">
          <p>
            Tel.:{" "}
            <IconLink
              href="tel:07151959930"
              className="underline focus:outline-none focus:ring-2 focus:ring-white"
            >
              07151 959930
            </IconLink>
          </p>
          <p>Fax: 07151 561127</p>
          <IconLink
            href="mailto:kanzlei@ra-waiblingen.de"
            className="underline block mt-2 focus:outline-none focus:ring-2 focus:ring-white"
          >
            kanzlei@ra-waiblingen.de
          </IconLink>
        </FooterColumn>

        {/* Öffnungszeiten */}
        <FooterColumn heading="Öffnungszeiten">
          <p>Montag - Donnerstag:</p>
          <p>8:30 - 12:00 Uhr, 14:00 - 17:00 Uhr</p>
          <p>Freitag:</p>
          <p>8:30 - 12:00 Uhr</p>
          <div className="inline-block mt-10">
            <h3 className="text-xl font-semibold text-yellow-ra">
              Sprechstunde Haus & Grund
            </h3>
            <div className="bg-yellow-ra h-[2px] w-full mt-4"></div>
          </div>
          <div className="mt-4">
            <p>
              Persönlich (mit{" "}
              <IconLink
                href="https://hausundgrundwnwi.de/service/veranstaltungen/rechtsberatung-waiblingen/"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                Anmeldung
              </IconLink>
              ):
            </p>
            <p>Freitag: 15:00 - 17:00 Uhr</p>
            <p className="mt-2">Telefonisch:</p>
            <p>Freitag: 15:00 - 16:00 Uhr</p>
          </div>
        </FooterColumn>
      </div>

      {/* Impressum & Datenschutz Links */}
      <div className="mt-20 text-center text-yellow-ra">
        <Link to="/impressum" className="hover:text-gray-500 mx-3">
          Impressum
        </Link>
        <Link to="/datenschutz" className="hover:text-gray-500 mx-3">
          Datenschutz
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
