import Hero from "./components/Hero";
import IntroText from "./components/IntroText";
import CTA from "./components/CTA";
import AnwaelteSection from "./components/AnwaelteSection";
import JobAdSection from "./components/JobAdSection";
import FachgebieteSection from "./components/FachgebieteSection";
import Nachruf from "./components/Nachruf";

const Home = () => {
  return (
    <>
      <Hero />
      <IntroText />
      <CTA />
      <AnwaelteSection />
      <JobAdSection />
      <FachgebieteSection />
      <Nachruf />
    </>
  );
};

export default Home;
