import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import Lightbox from "./Lightbox";
import oei from "../assets/images/oei.jpg";
import leibfritzjun from "../assets/images/leibfritz-jun.jpg";
import maier from "../assets/images/maier.jpg";
import kretschmar from "../assets/images/kretschmar.jpg";
import leibfritz from "../assets/images/leibfritz.jpg";

const advisors = [
  {
    name: "Yannick Oei",
    job: "Rechtsanwalt, Partner",
    specialty: "",
    image: oei,
    expertise: [
      "Mietrecht",
      "Wohnungseigentumsrecht",
      "Immobilienrecht",
      "Gewerbemietrecht",
      "Nachbarrecht",
    ],
  },
  {
    name: "Hendrik Leibfritz",
    job: "Rechtsanwalt, Partner",
    specialty: "Fachanwalt für Miet- und Wohnungseigentumsrecht",
    image: leibfritzjun,
    expertise: [
      "Mietrecht",
      "Wohnungseigentumsrecht",
      "Immobilienrecht",
      "Gewerbemietrecht",
      "Nachbarrecht",
      "Maklerrecht",
      "Erbrecht",
    ],
  },
  {
    name: "Hartwig Leibfritz",
    job: "Rechtsanwalt",
    specialty: "",
    image: leibfritz,
    expertise: [
      "Mietrecht",
      "Immobilienrecht",
      "Gewerbemietrecht",
      "Nachbarrecht",
    ],
  },
  {
    name: "Klaus Kretschmar",
    job: "Rechtsassessor",
    specialty: "",
    image: kretschmar,
    expertise: [
      "Privates Baurecht",
      "Öffentliches Baurecht",
      "Nachbarrecht",
      "Mietrecht",
      "Wohnungseigentumsrecht",
      "Immobilienrecht",
      "Gewerbemietrecht",
    ],
  },
  {
    name: "Giacomo Maier",
    job: "Rechtsanwalt",
    specialty: "",
    image: maier,
    expertise: [
      "Mietrecht",
      "Wohnungseigentumsrecht",
      "Immobilienrecht",
      "Gewerbemietrecht",
      "Privates Baurecht",
      "Öffentliches Baurecht",
      "Nachbarrecht",
    ],
  },
];

const AnwaelteSection = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const advisorIndex = searchParams.get("berater");
  const selectedIndex = advisorIndex !== null ? Number(advisorIndex) : null;

  const selectedAdvisor = useMemo(() => {
    if (selectedIndex !== null && !isNaN(selectedIndex)) {
      return advisors[selectedIndex];
    }
    return null;
  }, [selectedIndex]);

  const openLightbox = (idx) => {
    setSearchParams({ berater: idx });
  };

  const closeModal = () => {
    searchParams.delete("berater");
    setSearchParams(searchParams);
  };

  return (
    <section id="anwaelte" className="py-20">
      <div className="container mx-auto break-words">
        <h2 className="text-3xl font-bold text-blue-ra">Ihre Berater</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-10">
          {advisors.map((advisor, index) => (
            <div
              key={index}
              className="relative group cursor-pointer rounded shadow-lg hover:shadow-xl"
              onClick={() => openLightbox(index)}
            >
              {/* Advisor Image */}
              <img
                src={advisor.image}
                alt={advisor.name}
                className="w-full h-auto object-contain rounded"
              />

              {/* Text for small screens */}
              <div className="block lg:hidden pt-4 pb-6 px-8 text-center">
                <h3 className="text-lg font-semibold">{advisor.name}</h3>
                <p className="mt-1">{advisor.job}</p>
                {advisor.specialty && (
                  <p className="mt-1 italic">{advisor.specialty}</p>
                )}
                <p className="underline text-sm text-gray-500 mt-2">
                  Mehr Infos
                </p>
              </div>

              {/* Hover Overlay for medium and up */}
              <div className="hidden lg:flex opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute inset-0 items-center justify-center">
                <div className="bg-blue-ra/90 text-yellow-ra p-4 rounded w-[90%] h-[90%] flex flex-col items-center justify-center text-center">
                  <h3 className="text-xl text-white font-semibold">
                    {advisor.name}
                  </h3>
                  <p className="mt-1">{advisor.job}</p>
                  {advisor.specialty && (
                    <p className="mt-3 text-sm italic">{advisor.specialty}</p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Show Lightbox if valid advisor is selected */}
      {selectedAdvisor && (
        <Lightbox closeModal={closeModal} selectedAdvisor={selectedAdvisor} />
      )}
    </section>
  );
};

export default AnwaelteSection;
