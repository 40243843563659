import React from "react";
import Form from "./components/Form";

const Kontakt = () => {
  return (
    <>
      {/* Full-width header section */}
      <section className="w-full bg-blue-ra text-yellow-ra py-10">
        <div className="container mx-auto text-center">
          <h1 className="text-3xl text-white font-bold">
            Kontaktieren Sie uns
          </h1>
          <p className="mt-2">
            Wir sind gerne für Sie da – ob per E-Mail, Telefon oder persönlich.
          </p>
        </div>
      </section>

      <div className="py-10 md:py-20">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            {/* Left Column: Contact Information */}
            <div className="space-y-10">
              <h2 className="text-3xl font-bold text-blue-ra mt-10">
                Kontaktdaten
              </h2>
              <div>
                <h3 className="text-xl font-semibold text-blue-ra">Adresse</h3>
                <div className="mt-2">
                  <p>Rechtsanwälte Schmid, Leibfritz & Kollegen</p>
                  <p>Fronackerstraße 22</p>
                  <p>71332 Waiblingen</p>
                  <a
                    href="https://maps.app.goo.gl/9cVZcRhmNa91UsnUA"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline mt-2 inline-block"
                  >
                    Auf Karte anzeigen
                  </a>
                </div>
              </div>

              <div>
                <h3 className="text-xl font-semibold text-blue-ra">Kontakt</h3>
                <div className="mt-2">
                  <p>
                    Tel.:{" "}
                    <a href="tel:07151959930" className="underline">
                      07151 959930
                    </a>
                  </p>
                  <p>Fax: 07151 561127</p>
                  <a
                    href="mailto:kanzlei@ra-waiblingen.de"
                    className="underline block mt-2"
                  >
                    kanzlei@ra-waiblingen.de
                  </a>
                </div>
              </div>

              <div>
                <h3 className="text-xl font-semibold text-blue-ra">
                  Öffnungszeiten
                </h3>
                <div className="mt-2">
                  <p>Montag - Donnerstag:</p>
                  <p>8:30 - 12:00 Uhr, 14:00 - 17:00 Uhr</p>
                  <p>Freitag:</p>
                  <p>8:30 - 12:00 Uhr</p>
                </div>
              </div>

              <div>
                <h3 className="text-xl font-semibold text-blue-ra">
                  Sprechstunde Haus & Grund
                </h3>
                <div className="mt-2">
                  <p>
                    Wir bieten eine Sprechstunde für Mitglieder des{" "}
                    <a
                      href="https://www.hausundgrundwnwi.de/"
                      className="underline hover:text-light-blue-ra"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Haus- und Grundbesitzerverein Waiblingen, Winnenden und
                      Umgebung e.V.
                    </a>{" "}
                    an. Melden Sie sich für eine persönliche Beratung bitte auf
                    der{" "}
                    <a
                      href="https://hausundgrundwnwi.de/service/veranstaltungen/rechtsberatung-waiblingen/"
                      className="underline hover:text-light-blue-ra"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Website des Haus & Grund
                    </a>{" "}
                    an.
                  </p>
                  <p className="mt-2">Persönlich (mit Anmeldung):</p>
                  <p>Freitag: 15:00 - 17:00 Uhr</p>
                  <p className="mt-2">Telefonisch:</p>
                  <p>Freitag: 15:00 - 16:00 Uhr</p>
                </div>
              </div>
            </div>

            {/* Right Column: Contact Form */}
            <div>
              <div className="bg-blue-ra text-yellow-ra p-6 md:p-10 rounded shadow-lg">
                <h2 className="text-3xl font-bold mb-4">Kontaktformular</h2>
                <Form />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Kontakt;
