import React from "react";
import { Link } from "react-router-dom";

const JobAdSection = () => {
  return (
    <section id="job-ad" className="py-20 bg-gray-100">
      <div className="container mx-auto break-words">
        {/* Section Heading */}
        <h2 className="text-3xl font-bold text-blue-ra">
          Stellenangebot: Rechtsanwalt (m/w/d)
        </h2>

        {/* One concise paragraph */}
        <div className="mt-10">
          <p className="text-gray-700 text-lg leading-relaxed">
            Unsere Kanzlei in Waiblingen sucht einen engagierten Rechtsanwalt
            (m/w/d) zur Verstärkung unseres Teams. In dieser
            verantwortungsvollen Position beraten und vertreten Sie unsere
            Mandanten in vielseitigen Rechtsgebieten.
            <br />
            Konnten wir Ihr Interesse wecken? Dann bewerben Sie sich jetzt per
            E-Mail unter{" "}
            <a
              href="mailto:kanzlei@ra-waiblingen.de"
              className="underline hover:text-blue-ra"
            >
              kanzlei@ra-waiblingen.de
            </a>
            .
          </p>
        </div>
        {/* Call-to-Action */}
        <div className="mt-12">
          <Link
            to="/kontakt"
            className="inline-block text-blue-ra bg-yellow-ra hover:bg-dark-yellow-ra font-semibold py-4 px-4 rounded transition-colors shadow"
          >
            Jetzt bewerben
          </Link>
        </div>
      </div>
    </section>
  );
};

export default JobAdSection;
