import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <>
      <section className="w-full bg-blue-ra text-yellow-ra py-10">
        <div className="container mx-auto text-center">
          <h1 className="text-3xl text-white font-bold">
            404 - Seite nicht gefunden
          </h1>
          <p className="mt-2">
            Die von Ihnen gesuchte Seite existiert nicht oder wurde verschoben.
          </p>
        </div>
      </section>

      <div className="py-10 md:py-20">
        <div className="container mx-auto">
          <div className="space-y-10 text-center">
            <Link
              to="/"
              className="inline-block bg-blue-ra text-yellow-ra font-semibold py-2 px-3 rounded hover:bg-light-blue-ra"
            >
              Zur Startseite
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;
