import { useState } from "react";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import { FiPhone, FiMail } from "react-icons/fi";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";

function Header() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <header className="relative border-b-2 h-20 flex items-center">
      <div className="container mx-auto flex items-center justify-between h-full">
        {/* Logo */}
        <Link
          to="/"
          onClick={() => setShowMenu(false)}
          className="flex items-center h-10 hover:opacity-80"
        >
          <img
            src={logo}
            alt="Kanzlei Logo"
            className="h-full w-auto object-contain mr-2"
          />
        </Link>

        {/* Desktop Navigation (hidden on small) */}
        <nav className="hidden sm:flex items-center gap-6 font-semibold">
          {/* Menu Links */}
          <Link to="/" className="hover:text-gray-500">
            Home
          </Link>
          <Link to="/kontakt" className="hover:text-gray-500">
            Kontakt
          </Link>
          <div></div>

          {/* Telephone Button: yellow-ra background, blue-ra text */}
          <a
            href="tel:07151959930"
            className="flex items-center gap-2 bg-yellow-ra text-blue-ra px-3 py-2 rounded hover:bg-dark-yellow-ra transition-colors"
          >
            <FiPhone />
            <span className="hidden lg:hidden xl:inline">07151 959930</span>
          </a>

          {/* E-Mail Button: blue-ra background, yellow-ra text */}
          <a
            href="mailto:kanzlei@ra-waiblingen.de"
            className="flex items-center gap-2 bg-blue-ra text-yellow-ra px-3 py-2 rounded hover:bg-light-blue-ra transition-colors"
          >
            <FiMail />
            <span className="hidden lg:hidden xl:inline">
              kanzlei@ra-waiblingen.de
            </span>
          </a>
        </nav>

        {/* Mobile Menu Button (visible on small) */}
        <button
          onClick={() => setShowMenu(!showMenu)}
          className="sm:hidden font-bold text-xl hover:text-gray-500"
          aria-label="Toggle Menu"
        >
          {showMenu ? <GrClose /> : <GiHamburgerMenu />}
        </button>
      </div>

      {/* Mobile Navigation Overlay */}
      {showMenu && (
        <nav className="absolute top-full left-0 w-full bg-white border-b-2 p-4 flex flex-col items-start font-semibold z-50">
          {/* Menu Links */}
          <Link
            to="/"
            onClick={() => setShowMenu(false)}
            className="hover:text-gray-500 mb-6"
          >
            Home
          </Link>
          <Link
            to="/kontakt"
            onClick={() => setShowMenu(false)}
            className="hover:text-gray-500"
          >
            Kontakt
          </Link>

          {/* Horizontal line before phone & mail */}
          <hr className="my-6 w-full border-gray-300" />

          {/* Phone & Email in Mobile Menu (text is visible here) */}
          <a
            href="tel:07151959930"
            onClick={() => setShowMenu(false)}
            className="flex items-center gap-1 hover:text-gray-500 mb-6"
          >
            <FiPhone />
            07151 959930
          </a>
          <a
            href="mailto:kanzlei@ra-waiblingen.de"
            onClick={() => setShowMenu(false)}
            className="flex items-center gap-1 hover:text-gray-500"
          >
            <FiMail />
            kanzlei@ra-waiblingen.de
          </a>
        </nav>
      )}
    </header>
  );
}

export default Header;
