import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { FiSend, FiCheckCircle } from "react-icons/fi";
import { z } from "zod";

// Zod validation schema for the visible fields
// We won't include 'honeypot' in here, because we don't want it to fail normal validation.
const schema = z.object({
  user_name: z
    .string()
    .nonempty({ message: "Bitte geben Sie Ihren Namen ein." }),
  user_email: z
    .string()
    .email({ message: "Bitte geben Sie eine gültige E-Mail-Adresse ein." }),
  user_subject: z
    .string()
    .nonempty({ message: "Bitte geben Sie einen Betreff ein." })
    .max(100, { message: "Der Betreff darf maximal 100 Zeichen enthalten." }),
  message: z
    .string()
    .nonempty({ message: "Bitte verfassen Sie eine Nachricht." })
    .max(1000, { message: "Ihre Nachricht darf maximal 1000 Zeichen enthalten." }),
});

function Form() {
  const formRef = useRef();
  const [successMessage, setSuccessMessage] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data) => {
    if (data.honeypot) {
      setError("root", {
        message: "Ihre Anfrage konnte nicht gesendet werden.",
      });
      return;
    }

    try {
      await emailjs.sendForm(
        "service_6zbj1fv",
        "template_mi0ulpb",
        formRef.current,
        {
          publicKey: "kCeQYfafLYgvRWqOd",
        }
      );
      setSuccessMessage(
        "Ihre Nachricht wurde erfolgreich gesendet. Wir werden Ihnen so schnell wie möglich antworten. Vielen Dank!"
      );
      setHasSubmitted(true);
    } catch (error) {
      console.error("EmailJS error:", error);
      setError("root", {
        message: "Entschuldigung, etwas ist schiefgelaufen.",
      });
    }
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div className="hidden">
        <label>Leave this field empty</label>
        <input
          type="text"
          {...register("honeypot")}
          autoComplete="off"
          tabIndex={-1}
        />
      </div>

      {/* Name Field */}
      <div>
        <label htmlFor="user_name" className="block text-sm font-medium">
          Ihr Name
        </label>
        <input
          {...register("user_name")}
          name="user_name"
          id="user_name"
          type="text"
          placeholder="Max Mustermann"
          className="mt-1 block w-full border-0 rounded py-2 px-3 text-blue-ra
                     focus:outline-none focus:ring-2 focus:ring-yellow-ra"
        />
        {errors.user_name && (
          <div className="text-red-400 mt-2">{errors.user_name.message}</div>
        )}
      </div>

      {/* Email Field */}
      <div>
        <label htmlFor="user_email" className="block text-sm font-medium">
          Ihre E-Mail
        </label>
        <input
          {...register("user_email")}
          name="user_email"
          id="user_email"
          type="email"
          placeholder="max@mustermann.de"
          className="mt-1 block w-full border-0 rounded py-2 px-3 text-blue-ra
                     focus:outline-none focus:ring-2 focus:ring-yellow-ra"
        />
        {errors.user_email && (
          <div className="text-red-400 mt-2">{errors.user_email.message}</div>
        )}
      </div>

      {/* Subject Field */}
      <div>
        <label htmlFor="user_subject" className="block text-sm font-medium">
          Betreff
        </label>
        <input
          {...register("user_subject")}
          name="user_subject"
          id="user_subject"
          type="text"
          placeholder="Worum geht es?"
          className="mt-1 block w-full border-0 rounded py-2 px-3 text-blue-ra
                     focus:outline-none focus:ring-2 focus:ring-yellow-ra"
        />
        {errors.user_subject && (
          <div className="text-red-400 mt-2">{errors.user_subject.message}</div>
        )}
      </div>

      {/* Message Field */}
      <div>
        <label htmlFor="message" className="block text-sm font-medium">
          Ihre Nachricht
        </label>
        <textarea
          {...register("message")}
          name="message"
          id="message"
          rows="6"
          placeholder="Wie können wir Ihnen helfen?"
          className="mt-1 block w-full border-0 rounded py-2 px-3 text-blue-ra
                     focus:outline-none focus:ring-2 focus:ring-yellow-ra"
        />
        {errors.message && (
          <div className="text-red-400 mt-2">{errors.message.message}</div>
        )}
      </div>

      {/* Datenschutzhinweis */}
      <div className="text-sm">
        <p>
          Wir verwenden Ihre Angaben zur Beantwortung Ihrer Anfrage. Weitere
          Informationen finden Sie in unserer{" "}
          <Link
            to="/datenschutz"
            target="_blank"
            rel="noopener noreferrer"
            className="underline mt-2 focus:outline-none focus:ring-2 focus:ring-white"
          >
            Datenschutzerklärung
          </Link>
          .
        </p>
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        disabled={isSubmitting || hasSubmitted}
        className="flex items-center gap-2 bg-yellow-ra hover:bg-dark-yellow-ra
                   text-blue-ra font-semibold py-2 px-4 rounded transition-colors
                   disabled:opacity-60 disabled:cursor-not-allowed"
      >
        <FiSend />
        <span>{isSubmitting ? "Senden..." : "Senden"}</span>
      </button>

      {/* Root Errors (e.g., EmailJS failure) */}
      {errors.root && (
        <div className="text-red-400 mt-2">{errors.root.message}</div>
      )}

      {/* Success Message in a prominent green box */}
      {successMessage && (
        <div className="mt-4 p-4 bg-green-100 border border-green-500 rounded flex items-center gap-2">
          <FiCheckCircle className="text-green-600 text-4xl" />
          <span className="text-green-800 font-semibold">{successMessage}</span>
        </div>
      )}
    </form>
  );
}

export default Form;
