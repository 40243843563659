import React from "react";

const Impressum = () => {
  return (
    <>
      <section className="w-full bg-blue-ra text-yellow-ra py-10">
        <div className="container mx-auto text-center">
          <h1 className="text-3xl text-white font-bold">Impressum</h1>
          <p className="mt-2">
            Rechtliche Hinweise und Pflichtangaben – transparent und
            nachvollziehbar.
          </p>
        </div>
      </section>

      <section id="impressum" className="py-20">
        <div className="container mx-auto">
          <div className="inline-block mt-6 mb-4">
            <h2 className="text-2xl font-bold text-blue-ra">
              Rechtsanw&auml;lte Schmid &amp; Leibfritz
            </h2>
            <div className="bg-yellow-ra h-[2px] w-full mt-4"></div>
          </div>

          <p>
            Fronackerstr. 22
            <br />
            71332 Waiblingen
          </p>

          <p className="font-semibold">Vertreten durch:</p>
          <p>Yannick Oei und Hendrik Leibfritz</p>

          <h2 className="text-xl font-bold text-blue-ra mt-4">Kontakt</h2>
          <p>
            Telefon: 07151 959930
            <br />
            Telefax: 07151 561127
            <br />
            E-Mail: kanzlei@ra-waiblingen.de
          </p>

          <h2 className="text-xl font-bold text-blue-ra mt-4">
            Umsatzsteuer-ID
          </h2>
          <p>
            Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
            Umsatzsteuergesetz:
            <br />
            90488/10814
          </p>

          <h2 className="text-xl font-bold text-blue-ra mt-4">
            Berufsbezeichnung und berufsrechtliche Regelungen
          </h2>
          <p>
            Berufsbezeichnung:
            <br />
            Rechtsanwalt
          </p>
          <p>
            Zust&auml;ndige Kammer:
            <br />
            Rechtsanwaltskammer Stuttgart
            <br />
            K&ouml;nigstra&szlig;e 14
            <br />
            70173 Stuttgart
          </p>
          <p>
            Verliehen in:
            <br />
            Deutschland
          </p>
          <p>Es gelten folgende berufsrechtliche Regelungen:</p>
          <p>
            Berufsrecht: www.brak.de
            <br />
            einsehbar unter:{" "}
            <a
              href="https://www.brak.de/anwaltschaft/berufsrecht/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.brak.de/anwaltschaft/berufsrecht/
            </a>
          </p>
          <h2 className="text-xl font-bold text-blue-ra mt-4">
            Angaben zur Berufs&shy;haftpflicht&shy;versicherung
          </h2>
          <p className="font-semibold">Name und Sitz des Versicherers:</p>
          <p>Allianz Stuttgart</p>
          <p className="font-semibold">Geltungsraum der Versicherung:</p>
          <p>Deutschland</p>

          <h2 className="text-xl font-bold text-blue-ra mt-4">
            Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
          </h2>
          <p>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>

          <h2 className="text-xl font-bold text-blue-ra mt-4">
            Webdesign & Gestaltung
          </h2>
          <p>Victor Oei</p>
        </div>
      </section>
    </>
  );
};

export default Impressum;
