import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/scrollToTop";
import Home from "./Home";
import Kontakt from "./Kontakt";
import Impressum from "./Impressum";
import Datenschutz from "./Datenschutz";
import NotFound from "./NotFound";
import "./App.css";

function Layout({ children }) {
  return (
    <>
      <ScrollToTop />
      <div className="flex flex-col min-h-screen font-sans text-gray-800">
        <Header />
        <main className="flex-grow">{children}</main>
        <Footer />
      </div>
    </>
  );
}

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Layout>
          <Home />
        </Layout>
      ),
      errorElement: <NotFound />,
    },
    {
      path: "/kontakt",
      element: (
        <Layout>
          <Kontakt />
        </Layout>
      ),
    },
    {
      path: "/impressum",
      element: (
        <Layout>
          <Impressum />
        </Layout>
      ),
    },
    {
      path: "/datenschutz",
      element: (
        <Layout>
          <Datenschutz />
        </Layout>
      ),
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
