import React, { useEffect } from "react";

function Lightbox({ closeModal, selectedAdvisor }) {
  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  if (!selectedAdvisor) return null;

  return (
    <div
      className="fixed inset-0 bg-black/70 flex items-center justify-center z-50"
      onClick={closeModal}
    >
      <div
        className="bg-white rounded max-w-6xl w-full max-h-[95%] m-4 relative flex flex-col sm:flex-row overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={closeModal}
          className="absolute top-2 right-4 text-white sm:text-gray-800 hover:text-gray-600 font-bold text-3xl"
        >
          &times;
        </button>

        {/* Image Section */}
        <div className="sm:w-1/2 flex-shrink-0 bg-blue-ra">
          <img
            src={selectedAdvisor.image}
            alt={selectedAdvisor.name}
            className="w-auto h-full mx-auto object-cover max-h-[50vh] sm:max-h-[80vh]"
          />
        </div>

        {/* Info Section */}
        <div className="sm:w-1/2 p-8 overflow-y-auto sm:max-h-[80vh]">
          <div className="inline-block">
            <h3 className="text-2xl text-blue-ra font-bold">
              {selectedAdvisor.name}
            </h3>
            <div className="bg-yellow-ra h-[2px] w-full mt-4"></div>
          </div>
          <p className="mt-4 font-semibold">{selectedAdvisor.job}</p>
          {selectedAdvisor.specialty && (
            <p className="mt-2">{selectedAdvisor.specialty}</p>
          )}

          {Array.isArray(selectedAdvisor.expertise) &&
            selectedAdvisor.expertise.length > 0 && (
              <>
                <div className="mt-8">
                  <h3 className="text-xl text-blue-ra font-bold">
                    Fachgebiete
                  </h3>
                </div>
                <ul className="list-disc list-inside mt-2">
                  {selectedAdvisor.expertise.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </>
            )}

          <div className="mt-8">
            <p>
              Beratender Jurist des{" "}
              <a
                href="https://www.hausundgrundwnwi.de/"
                className="underline hover:text-light-blue-ra"
                target="_blank"
                rel="noopener noreferrer"
              >
                Haus- und Grundbesitzerverein Waiblingen, Winnenden und Umgebung
                e.V.
              </a>
              , dem Interessenverband der Haus-, Wohnungs- und Grundeigentümer
              in Waiblingen und Winnenden.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Lightbox;
