import React from "react";
import { FiExternalLink, FiMail, FiPhone } from "react-icons/fi";

const IconLink = ({ href, children, target, rel, className, ...props }) => {
  let Icon = null;

  if (href?.startsWith("mailto:")) {
    Icon = FiMail;
  } else if (href?.startsWith("tel:")) {
    Icon = FiPhone;
  } else if (target === "_blank") {
    // or a domain-check if you prefer
    Icon = FiExternalLink;
  }

  return (
    <a
      href={href}
      target={target}
      rel={rel}
      className={`inline-flex items-center gap-1 ${className}`}
      {...props}
    >
      {Icon && <Icon />}
      {children}
    </a>
  );
};

export default IconLink;
