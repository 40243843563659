import React from "react";
import schmidImage from "../assets/images/schmid.jpg";

const Nachruf = () => {
  return (
    <section id="nachruf" className="py-20 bg-gray-100">
      <div className="container mx-auto">
        {/* Content Container */}
        <div className="flex flex-col md:flex-row items-start gap-10">
          {/* Image: bottom on small screens, left on md+ */}
          <div className="order-2 md:order-1 md:w-1/4 flex-shrink-0 mx-auto">
            <img
              src={schmidImage}
              alt="Gunter Schmid"
              className="rounded shadow w-full h-auto object-cover max-w-[50vw] grayscale"
            />
          </div>

          {/* Text: top on small screens, right on md+ */}
          <div className="order-1 md:order-2 md:w-3/4">
            <h2 className="text-3xl font-bold text-blue-ra">
              In Gedenken an Gunter Schmid
            </h2>
            <p className="mt-8">
              In tiefer Trauer geben wir bekannt, dass unser geschätzter Kollege
              Herr Rechtsanwalt Gunter Schmid im Alter von 86 Jahren verstorben
              ist.
            </p>
            <p>
              Gunter Schmid prägte fast 60 Jahre lang unsere Kanzlei. Er
              übernahm die bereits 1932 gegründete Kanzlei im Jahr 1966 und
              formte sie – gemeinsam mit seinen Partnern und Kollegen –
              maßgeblich zu dem, was sie heute ist. Bis zuletzt war er als
              Rechtsanwalt tätig, dies mit niemals nachlassendem Engagement und
              stetigem vollen Einsatz für seine Mandanten.
            </p>
            <p>
              Seine Einsatzbereitschaft, sein unermüdliches Engagement, seine
              Verhandlungsstärke und seine Beharrlichkeit waren unter Kollegen
              und Mandanten bekannt und geschätzt.
            </p>
            <p>
              Für uns jüngere Kollegen war er stets ein wertvoller Mentor. Wir
              werden Gunter Schmid als ausgezeichneten Juristen, als
              warmherzigen wie auch humorvollen Menschen und als Freund in
              Erinnerung behalten. Unser tiefstes Mitgefühl gilt seiner Familie
              und allen, die ihm nahestanden. Wir werden ihn sehr vermissen.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Nachruf;
