import React from "react";
import OverviewBlocks from "./OverviewBlocks";

const IntroText = () => {
  return (
    <section className="py-20">
      <div className="container mx-auto break-words">
        <h1 className="uppercase">Rechtsanwälte Schmid & Leibfritz</h1>

        <h2 className="text-3xl font-bold text-blue-ra mt-2">
          Erfahrene Rechtsberatung – seit über 90 Jahren
        </h2>

        <p className="text-lg mt-8">
          Wir sind eine alteingeführte Kanzlei aus Waiblingen, gegründet 1932.
          Unsere Spezialgebiete sind Mietrecht und Wohnungseigentumsrecht sowie
          öffentliches und privates Baurecht. Wir bieten Ihnen mit unserem
          kompetenten Fachwissen aber auch Beratung und Vertretung im
          Arbeitsrecht, Familienrecht, Verkehrsrecht und Erbrecht an.
        </p>

        <p className="text-lg mt-4">
          Auf diesen Seiten erfahren Sie mehr über unser Team und unsere
          Fachgebiete. Gerne können Sie direkt Kontakt mit uns aufnehmen –
          telefonisch, per E-Mail oder persönlich vor Ort.
        </p>
        <p className="text-lg mt-4">
          Bereits seit 1972 stehen wir außerdem den Mitgliedern des{" "}
          <a
            href="https://www.hausundgrundwnwi.de/"
            className="underline hover:text-light-blue-ra"
            target="_blank"
            rel="noopener noreferrer"
          >
            Haus- und Grundbesitzerverein Waiblingen, Winnenden und Umgebung
            e.V.
          </a>{" "}
          mit Rat und Tat zur Seite. Vertrauen Sie auf unsere langjährige
          Erfahrung und unser Engagement für Ihr gutes Recht.
        </p>
      </div>
      <OverviewBlocks />
    </section>
  );
};

export default IntroText;
